import React from 'react'
import cn from 'classnames'
import { graphql } from 'gatsby'

import Layout from '@c/layout'
import Breadcrumb from '@c/breadcrumb'
import Icon from '@c/icon'
import { FlexibleLink } from '@c/common/FlexibleLink'
import DatoCmsLink from '@m/datocms-components/DatoCmsLink'
import DatoCMSModel from '@m/DatoCMSModel'
import Hero from '@v/contracts/hero'

const IndexElem = ({ i, elem }) => {
  const link = new DatoCmsLink(elem)

  if (elem.type === 'DatoCmsContentIndexSectionTitle') {
    return (
      <p className={cn('fs-x-small c-text', i > 0 && 'mt-60')}>{elem.title}</p>
    )
  }

  // elem.type === 'DatoCmsLink'

  return (
    <div className="content-jumplink mb-8 border-b-white">
      <FlexibleLink
        className="d-flex align-items-center"
        url={link.getUrl()}
        title={
          <>
            <p style={{ width: 90 + '%' }}>{link.getTitle()}</p>
            <Icon className="d-none icon ml-auto" />
          </>
        }
      />
    </div>
  )
}

const Index = ({ index }) => {
  return (
    <div className="u-sticky">
      {index.map((elem, i) => (
        <IndexElem key={elem.id} i={i} elem={elem} />
      ))}
    </div>
  )
}

const Page = ({ data, pageContext }) => {
  const { page } = data
  const { title } = page
  const model = new DatoCMSModel(page)

  return (
    <Layout
      locale={pageContext.locale}
      seo={{ ...model.getSeoComponentProps() }}
    >
      {/* BREADCRUMB */}
      <div className="container mb-60">
        <div className="row">
          <div className="col-12">
            <Breadcrumb
              parentPages={[]}
              currentPage={title}
              wrapCurrentPageWithH1={true}
            />
          </div>
        </div>
      </div>

      {/* PAGE */}
      <div className="container">
        <div className="row">
          {/* SIDEBAR */}
          <div className="col-12 col-lg-2 d-none d-lg-block">
            <Index index={page.index} />
          </div>

          {/* CONTENT */}
          <div className="col-12 col-lg-8">
            {/* HERO */}
            <Hero {...data.page} />

            {/* DESCRIPTION */}
            <div
              className="mt-40"
              dangerouslySetInnerHTML={{ __html: page.description }}
            ></div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page

export const query = graphql`
  query ContractsPageQuery($locale: String) {
    page: datoCmsContractPage(locale: { eq: $locale }) {
      _allSlugLocales {
        locale
        value
      }
      metaTags {
        ...MetaTags
      }
      locale
      title
      slug
      description
      index {
        type: __typename
        ... on DatoCmsContentIndexSectionTitle {
          id
          title
        }
        ... on DatoCmsLink {
          id
          title
          url
          page {
            ...DatoCmsLinkFieldPage
          }
        }
      }

      userAgreementTitle
      userAgreementSlug
      userAgreementDescription
      userAgreementLinkText
      userAgreementImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }

      privacyPolicyTitle
      privacyPolicySlug
      privacyPolicyDescription
      privacyPolicyLinkText
      privacyPolicyImage {
        alt
        asset {
          localImage {
            extension
            publicURL
          }
        }
      }
    }
  }
`
