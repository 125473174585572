import React from 'react'
import { Link } from 'gatsby'

import Icon from '@c/icon'
import makeSlug from '../../utils/make-slug'
import ProjectImage from '@/components/project-image'

const Hero = ({
  locale,
  slug, // listingPageSlug

  userAgreementTitle,
  userAgreementSlug,
  userAgreementDescription,
  userAgreementLinkText,
  userAgreementImage,

  privacyPolicyTitle,
  privacyPolicySlug,
  privacyPolicyDescription,
  privacyPolicyLinkText,
  privacyPolicyImage,
}) => (
  <div className="row align-items-lg-stretch">
    {/* card 1 */}
    <div className="col-12 col-xl-5 mb-40 mb-lg-0">
      <Link
        to={makeSlug.generic(locale, slug, userAgreementSlug)}
        className="border-box border-box--hover d-flex flex-column bg-red c-white"
      >
        <h4>{userAgreementTitle}</h4>
        <div
          className="mt-24"
          dangerouslySetInnerHTML={{ __html: userAgreementDescription }}
        />
        <div className="my-24">
          <span className="fs-small">
            {userAgreementLinkText}
            <Icon className="ml-8" name="right" />
          </span>
        </div>
        <figure className="mr-n32 mb-n32 ta-right">
          {userAgreementImage && <ProjectImage image={userAgreementImage} />}
        </figure>
      </Link>
    </div>
    {/* card 2 */}
    <div className="col-12 col-xl-5 offset-xl-2">
      <Link
        to={makeSlug.generic(locale, slug, privacyPolicySlug)}
        className="border-box border-box--hover d-flex flex-column"
        style={{ minHeight: '100%' }}
      >
        <h4>{privacyPolicyTitle}</h4>
        <div
          className="mt-24"
          dangerouslySetInnerHTML={{ __html: privacyPolicyDescription }}
        />
        <div className="my-24">
          <span className="fs-small">
            {privacyPolicyLinkText}
            <Icon className="ml-8" name="right" />
          </span>
        </div>
        <figure className="mb-n32 mx-n32 mt-auto ta-right bg-light-pink">
          {privacyPolicyImage && <ProjectImage image={privacyPolicyImage} />}
        </figure>
      </Link>
    </div>
  </div>
)

export default Hero
